import React from 'react';
import styled from 'styled-components';
import { media } from 'ui';
import TextToSpeechButton from 'components/TextToSpeechButton';
import { get } from 'lodash';
import { PrismicRichText } from '@prismicio/react';

const ToolboxTitle = styled.h3`
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 26px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const CardFooter = styled.div`
  background-color: ${({ theme }) => theme.backgroundToolbox};
  margin-left: ${({ theme }) => theme.spacing(-1)};
  padding-left: ${({ theme }) => theme.spacing()};
  padding-top: ${({ theme }) => theme.spacing()};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(-2)};
  margin-right: -80px;
  padding-right: 80px;
  color: ${({ theme }) => theme.textLight};

  ${media.tablet`
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-left: calc(96px + ${({ theme }) => theme.spacing(2 + 1 + 1)})
  `}
`;

const Toolbox = ({ article }) => {
  return (
    <CardFooter id={`toolbox-${get(article, '_meta.uid')}`}>
      <ToolboxTitle>Boîte à outils</ToolboxTitle>

      <TextToSpeechButton
        id={`toolbox-${get(article, '_meta.uid')}`}
        index={`toolbox-${get(article, '_meta.uid')}`}
        shouldShowOverlay={false}
      />

      <PrismicRichText field={article.data.toolbox.richText} />
    </CardFooter>
  );
};

export default Toolbox;
