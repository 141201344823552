/* eslint-disable jsx-a11y/no-access-key */

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { GATSBY_READSPEAKER_USER_ID } from '../constants';
import { ButtonReset } from 'ui';
import { get } from 'lodash';

const Wrapper = styled.div`
  position: relative;

  ${({ variant }) =>
    variant === 'articleCard' &&
    `
      padding-bottom:0;
    `}
`;

const Inner = styled.div``;

const ClickLayer = styled(ButtonReset)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: block;
`;

const TextToSpeechButton = ({
  id,
  index,
  variant,
  onClick,
  shouldShowOverlay,
}) => {
  const linkRef = useRef();

  useEffect(() => {
    if (!get(window, 'rspkr.ui.addClickEvents')) {
      return;
    }

    window.rspkr.ui.addClickEvents();
  }, []);

  const handleClick = () => {
    onClick();
    setTimeout(() => {
      linkRef.current.click();
    });
  };

  return (
    <Wrapper
      id={`readspeaker_button${index}`}
      className="rs_skip rsbtn rs_preserve"
      variant={variant}
    >
      <Inner>
        {shouldShowOverlay && Boolean(onClick) && (
          <ClickLayer onClick={handleClick} />
        )}

        <a
          rel="nofollow"
          className="rsbtn_play"
          accessKey="L"
          title="Écoutez le texte avec ReadSpeaker webReader"
          href={`//app-eu.readspeaker.com/cgi-bin/rsent?customerid=${GATSBY_READSPEAKER_USER_ID}&amp;lang=fr_fr&amp;readid=${id}&amp;url=`}
          ref={linkRef}
          onClick={e => e.preventDefault()}
        >
          <span className="rsbtn_left rsimg rspart">
            <span className="rsbtn_text">
              <span>Écouter</span>
            </span>
          </span>
          <span className="rsbtn_right rsimg rsplay rspart"></span>
        </a>
      </Inner>
    </Wrapper>
  );
};

export default TextToSpeechButton;
