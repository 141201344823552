import React from 'react';
import styled from 'styled-components';
import {
  Media,
  MediaImage,
  MediaBody,
  ButtonReset,
  Buttons,
  Button,
  RawLink,
} from 'ui';
import { asText } from '@prismicio/richtext';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import TextToSpeechButton from 'components/TextToSpeechButton';
import { PrismicRichText } from '@prismicio/react';

const NetworkWrapper = styled(Media)`
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
  align-items: flex-start;

  ${MediaBody} {
    padding-left: 0;
    margin-left: ${({ theme }) => theme.spacing()};
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  + * {
    ${MediaBody} {
      position: relative;
      &:before {
        content: '';
        top: ${({ theme }) => theme.spacing(-0.5)};
        right: 0;
        left: 0;
        position: absolute;
        border-top: 1px solid ${({ theme }) => theme.separator};
      }
    }
  }
`;

const Image = styled.img`
  width: 40px;
`;

const NetworkHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.textLight};
  padding-right: ${({ theme }) => theme.spacing(4)};
  position: relative;
  z-index: 2;
  width: 100%;

  h3 {
    font-size: 1.25rem;
    text-align: left;
    display: flex;
    min-height: 40px;
    align-items: center;
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const NetworkContent = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.textLight};
  text-align: left;
  line-height: 1.5;
`;

const Icon = styled.div`
  position: absolute;
  font-size: 2rem !important;
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  right: ${({ theme }) => theme.spacing()};
  top: 0;
  z-index: 1;
  height: 40px;
`;

const NetworkBody = styled(MediaBody)`
  position: relative;
`;

const Network = ({ network, setExpandedNetwork, expandedNetwork }) => {
  const handleClick = () => {
    if (expandedNetwork === network?.uid) {
      setExpandedNetwork(null);
    } else {
      setExpandedNetwork(network?.uid);
    }
  };

  return (
    <NetworkWrapper>
      <MediaImage>
        <Image src={network?.document?.data?.pictogram?.url}></Image>
      </MediaImage>

      <NetworkBody>
        <Icon>
          {expandedNetwork === network?.uid ? (
            <MdExpandLess />
          ) : (
            <MdExpandMore />
          )}
        </Icon>

        <div id={network?.uid}>
          <NetworkHeader as={ButtonReset} onClick={handleClick}>
            <h3>{asText(network?.document?.data?.name?.richText)}</h3>
          </NetworkHeader>

          <TextToSpeechButton
            id={network?.uid}
            index={network?.uid}
            shouldShowOverlay={expandedNetwork !== network?.uid}
            onClick={() => setExpandedNetwork(network?.uid)}
          />

          {expandedNetwork === network?.uid && (
            <>
              <NetworkContent>
                <PrismicRichText
                  field={network?.document?.data.description?.richText}
                />
              </NetworkContent>
              {network?.document?.data.network_kind === 'Avancé' &&
                Boolean(network?.document?.data?.category) && (
                  <Buttons>
                    <Button
                      variant="primary"
                      as={RawLink}
                      to="/#carte"
                      state={{
                        poiCategory: network?.document?.data.category?.uid,
                      }}
                    >
                      Voir sur la carte
                    </Button>
                  </Buttons>
                )}
            </>
          )}
        </div>
      </NetworkBody>
    </NetworkWrapper>
  );
};

export default Network;
