import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RawTruncate from 'react-truncate';
import { motion } from 'framer-motion';
import { get } from 'lodash';
import { ButtonReset, media } from 'ui';
import { Tabs, Tab, TabNavButton } from 'ui/tabs';
import Toolbox from './Toolbox';
import Network from './Network';
import TextToSpeechButton from 'components/TextToSpeechButton';
import { PrismicRichText } from '@prismicio/react';

const Card = styled(motion.article)`
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-bottom: ${({ theme }) => theme.spacing()};
  padding: ${({ theme }) => theme.spacing()};
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.card.background};

  ${media.desktop`
    padding-right: 80px;
  `}

  h1 {
    color: ${({ theme }) => theme.primary};
    margin-top: 0;
    margin-bottom: 0;
  }

  a {
    color: ${({ theme }) => theme.linkColor};
    text-decoration: none;
  }
`;

const CardTitle = styled.button`
  display: flex;
  cursor: pointer;
  background: none;
  border: none;
  margin-bottom: ${({ theme }) => theme.spacing()};
  text-align: inherit;

  h1 {
    line-height: 1.1;
  }

  h3 {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing()};
    color: ${({ theme }) => theme.primary};
    font-size: 1.5rem;
    font-weight: 600;
  }
`;

const CardContent = styled.div`
  color: ${({ theme }) => theme.textLight};
  padding-bottom: ${({ theme }) => theme.spacing()};
  > *:last-child {
    margin-bottom: 0;
  }
`;

const ArticleCardImage = styled.img`
  width: 48px;
  height: 48px;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;

  ${media.tablet`
    margin-right: 0;
    width: 96px;
    height: 96px;
  `}
`;

const Button = styled(ButtonReset)`
  height: 64px;
  width: 64px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.secondary};
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

const WrapperIcon = styled.div`
  position: relative;
`;

const Horizontal = styled(motion.div)`
  position: absolute;
  background-color: #fff;
  width: 20px;
  height: 4px;
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -2px;
`;

const Vertical = styled(motion.div)`
  position: absolute;
  background-color: #fff;
  width: 4px;
  height: 20px;
  left: 50%;
  margin-left: -2px;
  top: 50%;
  margin-top: -10px;
`;

const Truncate = styled(RawTruncate)`
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const ArticleMedia = styled.div`
  ${media.tablet`
    display: flex;
    padding: ${({ theme }) => theme.spacing()};
  `}
`;

const ArticleImage = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};

  ${media.tablet`
    margin-bottom: 0;
  `}
`;

const ArticleBody = styled.div`
  flex: 1;
  padding-top: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding-top: 0;
    padding-left: ${({ theme }) => theme.spacing(2)};
  `}
`;

const ArticleCard = ({ article, index, setSelected, hasToClose }) => {
  const { shouldHaveCategorySlugId } = article;
  const [expanded, setExpanded] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState('Basique');
  const [expandedNetwork, setExpandedNetwork] = useState(null);

  const specialty = article?.data?.specialty;

  const selectedNetworks = get(article, 'data.networks', []).filter(
    ({ network }) =>
      get(network, 'document.data.network_kind', '') === selectedNetwork,
  );

  useEffect(() => {
    if (hasToClose && expanded) {
      setExpanded(false);
    }
  }, [hasToClose, expanded]);

  const expandArticle = () => {
    setExpanded(!expanded);
    setSelected(index);
  };

  return (
    <>
      {shouldHaveCategorySlugId && <span id={specialty?.document?.uid} />}

      <Card initial={expanded} transition={{ ease: 'easeOut', duration: 0.25 }}>
        <ArticleMedia>
          <ArticleImage>
            <ArticleCardImage
              onClick={expandArticle}
              src={get(
                article,
                'data.specialty.document.data.pictogram.url',
                '',
              )}
              alt={get(
                article,
                'data.specialty.document.data.pictogram.name',
                '',
              )}
            />
          </ArticleImage>

          <ArticleBody>
            <div id={`article-${index}`}>
              <CardTitle onClick={expandArticle}>
                <PrismicRichText field={article.data.title.richText} />
              </CardTitle>
              <TextToSpeechButton
                id={`article-${index}`}
                index={index}
                onClick={() => {
                  setExpanded(true);
                  setSelected(index);
                }}
                shouldShowOverlay={!expanded}
              />

              <CardContent>
                <Truncate lines={expanded ? false : 6} ellipsis="…">
                  <PrismicRichText field={article.data.description.richText} />
                </Truncate>
              </CardContent>
            </div>

            <CardContent>
              {expanded && Boolean(article?.data?.networks?.[0].network) && (
                <>
                  <Tabs>
                    <Tab>
                      <TabNavButton
                        isActive={selectedNetwork === 'Basique'}
                        onClick={() => setSelectedNetwork('Basique')}
                      >
                        Près de chez vous
                      </TabNavButton>
                    </Tab>
                    <Tab>
                      <TabNavButton
                        isActive={selectedNetwork === 'Avancé'}
                        onClick={() => setSelectedNetwork('Avancé')}
                      >
                        En cas de difficulté dans le soin
                      </TabNavButton>
                    </Tab>
                  </Tabs>

                  {selectedNetworks.map(({ network }) => (
                    <Network
                      key={network?.uid}
                      network={network}
                      expandedNetwork={expandedNetwork}
                      setExpandedNetwork={setExpandedNetwork}
                    />
                  ))}
                </>
              )}
            </CardContent>
          </ArticleBody>
        </ArticleMedia>

        {expanded && <Toolbox article={article} />}

        <Button onClick={expandArticle}>
          <WrapperIcon>
            <Horizontal
              initial={expanded}
              transition={{ ease: 'easeOut', duration: 0.25 }}
              animate={{
                transform: expanded ? 'rotate(90deg)' : 'rotate(-90deg)',
                opacity: expanded ? 0 : 1,
              }}
            />
            <Vertical
              initial={expanded}
              transition={{ ease: 'easeOut', duration: 0.25 }}
              animate={{
                transform: expanded ? 'rotate(90deg)' : 'rotate(-90deg)',
              }}
            />
          </WrapperIcon>
        </Button>
      </Card>
    </>
  );
};

export default ArticleCard;
