import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { Container, media } from '../ui';
import manUpper from '../images/man-upper.png';
import womanUpper from '../images/woman-upper.png';
import logo from '../images/logo-pro.png';
import darkLogo from '../images/logo-pro-dark.png';
import { WidgetContext } from '../WidgetProvider';

const getImageUrlFromGender = gender => {
  switch (gender) {
    case 'Homme':
      return manUpper;
    case 'Femme':
    default:
      return womanUpper;
  }
};

const Wrapper = styled.header`
  background: ${({ theme }) => theme.secondaryGradient};
  position: relative;
  overflow: hidden;
`;

const Main = styled.div`
  flex: 1 1 50vh;
  align-self: center;
`;

const TitleAndBubble = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const BubbleContent = styled.div`
  text-align: center;
  position: relative;
  z-index: 1;
  line-height: 0.8;
`;

const Bubble = styled.div`
  &:before {
    content: '';
    display: block;
    top: 0;
    right: 0;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.backgroundAlt};
    width: 100%;
    padding-bottom: 100%;
    position: absolute;
  }

  flex: 0 0 380px;
  min-height: 250px;
  position: relative;
  align-self: flex-end;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: ${({ theme }) => theme.spacing(2)};
  font-size: 5.125rem;
  color: #b0e0e1;

  ${media.desktop`
    img {
      width: 300px;
      max-width: 100%;
    }
  `}

  ${({ app }) =>
    app === 'pro' &&
    css`
      display: none;

      ${media.tablet`
        display: flex;
      `}
    `}
`;

const Age = styled.span`
  display: block;
  font-weight: 900;
  font-size: 1.1em;
  align-self: flex-end;
`;

const Silhouette = styled.img`
  position: absolute;
  right: ${({ theme }) => theme.spacing(-2)};
  bottom: 0;
  height: 280px;
  width: auto;
`;

const PageTitle = styled.h1`
  font-weight: 900;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #fff;
  line-height: 1.17;
  font-size: 2rem;

  ${media.tablet`
    font-size: 3rem;
  `}

  ${({ variant }) =>
    variant === 'withLogo' &&
    `
      font-size: 1.5rem;
    `}

  ${media.desktop`
    font-size: 4rem;
    line-height: 4.5rem;
    
    ${({ variant }) =>
      variant === 'withLogo' &&
      `
        font-size: 1.75rem;
      `}
  `}
`;

const LogoImg = styled.img`
  display: block;
  max-width: 100%;
  height: auto;

  ${media.tablet`
    width: 100%;
    max-width: 40vw;
  `}
`;

const LogoLink = styled(Link)``;

const Header = ({ title, icon, gender, age, variant, app }) => {
  const { mode } = useContext(WidgetContext);
  return (
    <Wrapper>
      <Container style={{ paddingBottom: 0 }}>
        <TitleAndBubble>
          <Main>
            {variant === 'withLogo' && (
              <LogoLink to={app === 'pro' ? '/pro' : '/'}>
                <LogoImg src={mode === 'LIGHT' ? logo : darkLogo} />
              </LogoLink>
            )}
            <PageTitle variant={variant}>{title}</PageTitle>
          </Main>

          {(Boolean(icon) || Boolean(gender)) && (
            <Bubble app={app}>
              <BubbleContent>
                {Boolean(icon) && <img src={icon} alt="" />}{' '}
                {Boolean(age) && (
                  <>
                    <Age>{age}</Age> ans
                  </>
                )}
              </BubbleContent>

              {Boolean(gender) && (
                <Silhouette src={getImageUrlFromGender(gender)} alt={gender} />
              )}
            </Bubble>
          )}
        </TitleAndBubble>
      </Container>
    </Wrapper>
  );
};

export default Header;
