import styled from 'styled-components';
import { List, ButtonReset, media } from './';

export const Tabs = styled(List)`
  display: flex;
  box-shadow: 0 1px 0 0 #dcdad3;
  margin-bottom: ${({ theme }) => theme.spacing()};
  max-width: 100vw;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  ${media.tablet`
    flex-wrap: wrap;
  `}
`;

export const Tab = styled.li`
  flex: 1;
  + li {
    padding-left: ${({ theme }) => theme.spacing()};
  }
`;

export const TabNavButton = styled(ButtonReset)`
  background: transparent;
  border: 0;
  appearance: none;
  color: ${({ theme }) => theme.textLight};
  text-decoration: none;
  display: block;
  padding: ${({ theme }) => theme.spacing()};
  outline: none;
  cursor: pointer;
  min-width: 10em;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap;

  ${({ isActive, theme }) =>
    isActive &&
    `
      color: ${theme.secondary};
      box-shadow: inset 0 -4px ${theme.secondary};
    `};

  &.active {
    color: ${({ theme }) => theme.secondary};
    box-shadow: inset 0 -4px ${({ theme }) => theme.secondary};
  }

  ${media.tablet`
    width: 100%;
  `}
`;
