import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { get, orderBy, find } from 'lodash';
import {
  SidebarLayout,
  Sidebar,
  Content,
  Container,
  SidebarMenu,
  SidebarIcon,
  SidebarInner,
  SidebarContent,
  SidebarMenuLink,
  SidebarText,
  Button,
} from 'ui';
import Header from 'components/Header';
import Seo from 'components/SEO';
import Layout from 'components/Layout';
import ArticleCard from 'components/ArticleCardBis';
import { useLocation } from 'react-use';
import { MdChevronLeft } from 'react-icons/md';
import { GATSBY_URL } from '../constants';
import { asText } from '@prismicio/richtext';

const SearchResults = ({ data, pageContext, location }) => {
  const { hash } = useLocation();
  const [selected, setSelected] = useState();

  // [1] Concatenate all gender specific and non-gender specific entries
  // [2] Order them by category
  // [3] Iterate over them and add a prop to show #slug as id to generate
  //     anchor links if article is first of specialty
  // 👇👇👇
  const articles = orderBy(
    [
      // [1]
      ...get(data, 'genderedArticles.edges', []),
      ...get(data, 'nonGenderedArticles.edges', []),
    ].map(({ node }) => ({ uid: node.uid, ...node })),
    ['data.specialty.document.uid'], // [2]
    ['asc'],
  ).reduce((acc, curr, index) => {
    const previousArticle = acc[index - 1];

    if (
      get(previousArticle, 'data.specialty.document.uid') !==
      get(curr, 'data.specialty.document.uid')
    ) {
      return [...acc, { ...curr, shouldHaveCategorySlugId: true }];
    }

    return [...acc, curr];
  }, []);

  // [1] Get all specialties, ordered by position.
  // [2] Filter out all specialties that have no related article.
  // 👇👇👇
  const specialties = orderBy(
    // [1]
    get(data, 'allPrismicSpecialty.edges', []).map(({ node }) => ({
      uid: node.uid,
      ...node,
    })),
    'uid',
  ).filter(
    (
      // [2]
      { uid },
    ) => {
      return find(
        articles,
        article => get(article, 'data.specialty.document.uid') === uid,
      );
    },
  );

  return (
    <Layout>
      <Seo
        title={`${pageContext.gender} de ${pageContext.age} an${
          pageContext.age > 1 ? 's' : ''
        }`}
      />
      <Header
        title="Prévention et soins"
        age={pageContext.age}
        gender={pageContext.gender}
      />

      <SidebarLayout>
        <Sidebar>
          <SidebarContent>
            <SidebarInner>
              <Button variant="primary" as={Link} to="/">
                <MdChevronLeft /> Retour
              </Button>
            </SidebarInner>

            <SidebarMenu>
              {specialties.map(({ data, uid }) => (
                <li key={uid}>
                  <SidebarMenuLink
                    as="a"
                    href={`#${uid}`}
                    isActive={`#${uid}` === `${hash}`}
                  >
                    <SidebarIcon src={data.icon.url} alt="" />
                    <SidebarText>{asText(data.name.richText)}</SidebarText>
                  </SidebarMenuLink>
                </li>
              ))}
            </SidebarMenu>
          </SidebarContent>
        </Sidebar>

        <Content>
          <Container position="left" paddingSize="large">
            {articles.map((article, index) => (
              <ArticleCard
                key={index}
                article={article}
                index={index}
                urlPrefix={`${GATSBY_URL}${location.pathname}`}
                hasToClose={index !== selected}
                setSelected={setSelected}
              />
            ))}
          </Container>
        </Content>
      </SidebarLayout>
    </Layout>
  );
};

export default SearchResults;

export const pageQuery = graphql`
  query SearchResultsGenderedPageQuery(
    $minAge: Float!
    $maxAge: Float!
    $gender: String!
  ) {
    allPrismicSpecialty {
      edges {
        node {
          uid
          data {
            name {
              richText
            }
            icon {
              alt
              copyright
              url
              gatsbyImageData
            }
            pictogram {
              alt
              copyright
              url
              gatsbyImageData
            }
            position
          }
        }
      }
    }
    genderedArticles: allPrismicArticle(
      filter: {
        data: {
          max_age: { gt: $maxAge }
          min_age: { lt: $minAge }
          gender: { eq: $gender }
        }
      }
    ) {
      edges {
        node {
          uid
          data {
            title {
              richText
            }
            specialty {
              document {
                ... on PrismicSpecialty {
                  uid
                  data {
                    name {
                      richText
                    }
                    position
                    pictogram {
                      alt
                      url
                      gatsbyImageData
                    }
                    icon {
                      alt
                      url
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            toolbox {
              text
              richText
            }
            description {
              text
              richText
            }
            networks {
              network {
                uid
                document {
                  ... on PrismicNetwork {
                    data {
                      name {
                        richText
                      }
                      description {
                        richText
                      }
                      pictogram {
                        alt
                        url
                        gatsbyImageData
                      }
                      network_kind
                      category {
                        uid
                        document {
                          ... on PrismicPoiCategory {
                            data {
                              name {
                                richText
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    nonGenderedArticles: allPrismicArticle(
      filter: {
        data: {
          max_age: { gt: $maxAge }
          min_age: { lt: $minAge }
          gender: { eq: "Indifférent" }
        }
      }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
              richText
            }
            specialty {
              document {
                ... on PrismicSpecialty {
                  uid
                  data {
                    name {
                      text
                      richText
                    }
                    position
                    pictogram {
                      alt
                      copyright
                      url
                      gatsbyImageData
                    }
                    icon {
                      alt
                      copyright
                      url
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            toolbox {
              text
              richText
            }
            description {
              text
              richText
            }
            networks {
              network {
                uid
                document {
                  ... on PrismicNetwork {
                    data {
                      name {
                        text
                        richText
                      }
                      description {
                        text
                        richText
                      }
                      pictogram {
                        alt
                        copyright
                        url
                        gatsbyImageData
                      }
                      network_kind
                      category {
                        uid
                        document {
                          ... on PrismicPoiCategory {
                            data {
                              name {
                                text
                                richText
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
